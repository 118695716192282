import React from 'react'

import Section from '../../../Section'

import stageImage from './stage.jpg'

const Stage = () => {
  return (
    <Section className="stage">
      <img className="stage__image" src={stageImage} alt="" />
    </Section>
  )
}

export default React.memo(Stage)
