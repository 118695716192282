import React from 'react'

import { Pages } from '../enums'

import siteData from '../content/data.json'

import DefaultLayout from '../templates/DefaultLayout'

import Stage from '../components/Pages/Home/Stage'
import Welcome from '../components/Pages/Home/Welcome'

import '../scss/styles.scss'

const Home = () => {
  return (
    <DefaultLayout title={siteData.home.title} activePage={Pages.HOME}>
      <Stage />
      <Welcome />
    </DefaultLayout>
  )
}

export default React.memo(Home)
