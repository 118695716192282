import React from 'react'

import siteData from '../../../../content/data.json'

import Section from '../../../Section'
import Container from '../../../Container'

const Welcome = () => {
  return (
    <Section className="welcome">
      <Container>
        <h1>{siteData.home.headline}</h1>
        <p>
          Während meiner Berufstätigkeit war ich langjährig im Institut für
          Obstzüchtung in Dresden-Pillnitz beschäftigt und habe auf dem Gebiet
          der Apfelzüchtung sowie der <a href="/mieze-nova">Erdbeer-</a> und{' '}
          <a href="/aroma-queen">Himbeerzüchtung</a> wissenschaftlich
          gearbeitet.
        </p>
        <p>
          Nach Eintritt in den Ruhestand züchte ich jetzt auf freiberuflicher
          Basis <a href="/mieze-nova">Erdbeersorten</a>,{' '}
          <a href="/aroma-queen">Himbeersorten</a> und kleinkronige{' '}
          <a href="/mini-cox">Apfelsorten</a>, die sich besonders für den Anbau
          im Kleingarten eignen.
        </p>
        <p>
          Bei <a href="/mieze-nova">Erdbeeren</a> und{' '}
          <a href="/aroma-queen">Himbeeren</a> ist mein vorrangiges Zuchtziel
          ein hervorragender Geschmack, wie er den alten Sorten, - z. B. der
          Erdbeersorte „Mieze Schindler“ - eigen ist. Auch die
          Widerstandsfähigkeit gegen Krankheiten hat für die neuen Sorten im
          Kleingarten große Bedeutung, um Fungizid-Spritzungen zu vermeiden.
        </p>
        <p>
          Bei den neuen Apfelsorten für den Kleingarten züchte ich Formen mit
          schwachem Wuchs und sog. Säulenformen, die wenig Platz beanspruchen
          und auch für Kübel auf Terrasse und Balkon geeignet sind. In meinen
          Apfelsorten <a href="/mini-cox">„Mini-Cox“ ®</a> und{' '}
          <a href="/coxcolumnar">„Coxcolumnar“ ®</a> ist die für ihren
          ausgezeichneten Geschmack bekannte, alte Apfelsorte „Cox Orangen
          Renette“ eingekreuzt, von der beide Sorten ein hervorragendes Aroma
          geerbt haben.
        </p>
        <p className="signature">Dr. Barbara Dathe</p>
      </Container>
    </Section>
  )
}

export default React.memo(Welcome)
